.App {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90vh;
  /* background-color: white; */
  /* background: url("/assets/bg.jpg") no-repeat center fixed; */
}

.name-field {
  margin-bottom: 5px;
  font-size: 18px;
  border-radius: 4px;
}

.name-field:focus {
  border: 3px solid #555;
}

.number-field {
  font-size: 18px;
  margin-bottom: 3px;
  border-radius: 4px;

}

.number-field:focus {
  border: 3px solid #555;
}


.front-page-view {
 
  text-align: center;
  /* padding: 10px; */
  height: 610px;
  overflow:visible;


}



.and-or-head{
  font-size: 23px;
  margin-top: 10px;
  margin-bottom: 10px;
  font-family: 'Inter', sans-serif;
  font-weight: 500;
}


textarea {
  width: 100%;
  height: 150px;
  margin: 10px auto;
  display: block;
  border: 2px solid black;
  border-radius: 8px;

}

input[type="file"] {
  margin: 10px auto;
  display: block;
  text-align: center;
}

table {
  margin: 10px auto;
  border-collapse: separate;
  width: 55%;
  /* background: url("/assets/bg.jpg") no-repeat center fixed; */

}

th, td {
  border: 1px solid black;
  padding: 8px;
  text-align: left;
}

th {
  background-color: white;
}

.downloadBtn {
  font-family: 'Inter', sans-serif;
  font-weight: 500;
  background-color: transparent;
  border: 1px solid white ;
  padding: 0.5em 1em;
  font-size: 16px;
  text-decoration-color: blue;
  text-decoration: underline;
  cursor: pointer;
}

.choose-input {
  margin-top: 10px;
  padding: 0px 1px 10px 70px;
  /* background-color:white; */
  color: rgb(82, 82, 82);
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
}

.description {
  font-family: 'Inter', sans-serif;
  font-weight: 500;
  font-size: 1.7rem;
  margin-bottom: 20px;
}

.sample-csv-file {
  font-family: 'Inter', sans-serif;
  font-weight: 900;
}

.import-csv {
  font-family: 'Inter', sans-serif;
  font-weight: 530;
  font-size: 1.3rem;
}
/* input[type="file"] {
  display: block;
  margin: 0 auto;
  text-align: center;
} */

.send-whatsapp-button {
  background-color: green;
  color: white;
  padding: 9px 18px;
  border-radius: 5px;
  border: none;
  font-size: 15px;
  cursor: pointer;
  margin-top: 10px;
  text-align: center;
}

.single-send-btn {
  background-color: green;
  color: white;
  padding: 9px 18px;
  border-radius: 5px;
  border: none;
  font-size: 15px;
  cursor: pointer;
  margin-top: 10px;
  text-align: center;
  margin-left: 15px;
  margin-bottom: 10px;
}

.single-send-btn:hover {
  background-color: lightgreen;
}

.send-whatsapp-button:hover {
  background-color: lightgreen;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@media (max-width: 600px) {
  table {
    font-size: 12px;
  }
}

.fixed-wrapper {
  max-width: 100vw;
  overflow-x: hidden;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.reCaptcha {
  height: 80px;
  width: 200px;
  visibility: visible;
  position: fixed;
  bottom: 0;
  right: 0;
}

/* FONT-AWESOME ICONS */

.copy-icon {
  margin-left: 10px;
  cursor: pointer;
  position: relative;
  background-color: white;
}

.save-icon {
  margin-right: 10px;
  cursor: pointer;
  position: relative;
}

.recall-icon {
  margin-left: 5px;
  cursor: pointer;
  position: relative;
}
.contact-us-icon-wrapper {
  list-style-type: none;
  text-align: center;
  margin: 0;
  padding: 0;
}

.contact-us-icon-wrapper li {
  display: inline-block;
  padding-right: 10px;
}
.contact-us-icon-wrapper a {
  color: black;
}
/* END */

