body {
  /* margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
  background: url("/assets/bg.jpg") no-repeat center fixed;
	-webkit-background-size: 100% 100%;
	-moz-background-size: 100% 100%;
	-o-background-size: 100% 100%;
	background-size: 100% 100%;
	background-size: cover;
	height: auto;
	background-position: 50% 50%;
	width: 100%;
}



code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.content-container:after {
  content: "";
  display: table;
  clear: both;
}



/* footer {
	color: white;
	text-align: center;

  width: 100%;

	background-color: #009477;
}

footer a,
footer a:hover {
	text-decoration: none !important;
	color: white;
	font-weight: bold;
}

.footer-width {
  max-width: 100%;

} */






